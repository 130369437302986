<template>
  <v-row>
    <v-col cols="12">
      <base-card>
        <v-card-title>
          <v-btn
            class="ma-2"
            color="secondary"
            @click="$router.back()"
            outlined
            dark
          >
            <v-icon>mdi-arrow-left</v-icon>
            Back
          </v-btn>
        </v-card-title>
        <v-card-title class="pl-6">Student</v-card-title>
        <div class="pa-5">
          <v-form ref="form">
            <v-autocomplete
              v-model="student.customer_id"
              @click:clear="student.customer_id = null"
              label="Customer"
              prepend-icon="mdi-face-man"
              :items="studentCustomers"
              :loading="gettingCustomers"
              :rules="[requiredRule]"
              item-text="description"
              item-value="id"
              autocomplete="off"
              outlined
              clearable
              hide-details
            >
              <template v-slot:append-item>
                <div v-intersect="onIntersect"></div>
              </template>
            </v-autocomplete>

            <v-text-field
              v-if="!isCreate"
              class="mt-8"
              label="Name"
              prepend-icon="mdi-text"
              :rules="[requiredRule]"
              v-model="student.name"
              outlined
            ></v-text-field>
            <v-text-field
              v-if="isCreate"
              class="mt-8"
              label="First Name"
              prepend-icon="mdi-text"
              :rules="[requiredRule]"
              v-model="student.first_name"
              outlined
            ></v-text-field>
            <v-text-field
              v-if="isCreate"
              label="Last Name"
              prepend-icon="mdi-text"
              :rules="[requiredRule]"
              v-model="student.last_name"
              outlined
            ></v-text-field>
            <v-text-field
              label="Email"
              prepend-icon="mdi-at"
              :rules="[requiredRule, emailRule]"
              v-model="student.email"
              outlined
            ></v-text-field>
            <v-col class="d-flex align-center pa-0">
              <v-text-field
                label="Username"
                prepend-icon="mdi-text"
                :loading="usernameLoading"
                :error-messages="usernameError"
                :rules="[requiredRule]"
                v-model="student.username"
                outlined
              ></v-text-field>
              <v-btn
                color="primary"
                class="ml-4 mb-8"
                @click="generateUserName"
              >
                Generate Username
              </v-btn>
            </v-col>
            <div v-if="isCreate" class="d-flex align-center">
              <v-text-field
                label="Password"
                prepend-icon="mdi-form-textbox-password"
                :append-icon="showPassword ? 'mdi-eye' : 'mdi-eye-off'"
                :type="showPassword ? 'text' : 'password'"
                @click:append="showPassword = !showPassword"
                :rules="[requiredRule]"
                v-model="student.password"
                outlined
              ></v-text-field>
              <v-btn
                color="primary"
                class="ml-4 mb-8"
                @click="generatePassword"
              >
                Generate Password
              </v-btn>
            </div>
            <v-text-field
              label="Function Name"
              prepend-icon="mdi-text"
              :rules="[requiredRule]"
              v-model="student.function"
              outlined
            ></v-text-field>
            <v-text-field
              v-if="!isCreate"
              label="Company Name"
              prepend-icon="mdi-text"
              v-model="student.company_name"
              outlined
            ></v-text-field>
            <v-text-field
              label="Number"
              prepend-icon="mdi-text"
              v-model="student.number"
              outlined
            ></v-text-field>
            <v-dialog
              v-if="!isCreate"
              ref="dialog"
              width="300"
              v-model="dialog"
            >
              <template v-slot:activator="{ on, attrs }">
                <v-text-field
                  class="filter-input"
                  prepend-icon="mdi-calendar-blank"
                  v-model="displayDate"
                  label="Birthdate"
                  v-bind="attrs"
                  :rules="[requiredRule]"
                  v-on="on"
                  clearable
                  outlined
                  readonly
                ></v-text-field>
              </template>
              <v-date-picker
                v-model="student.birthdate"
                @change="onBirthdateChange()"
                scrollable
              >
                <v-spacer></v-spacer>
                <v-btn text color="secondary" @click="dialog = false">
                  Close
                </v-btn>
              </v-date-picker>
            </v-dialog>
            <v-col class="py-0">
              <v-switch
                class="mt-2"
                label="Disabled"
                v-model="student.disabled"
                :true-value="1"
                :false-value="0"
                inset
              ></v-switch>
            </v-col>
            <v-row justify="end">
              <v-btn
                color="primary"
                @click="save"
                :disabled="loading"
                :loading="loading"
              >
                {{ isCreate ? "Create Student" : "Edit Student" }}
              </v-btn>
            </v-row>
          </v-form>
        </div>
      </base-card>
    </v-col>
  </v-row>
</template>

<script>
import { checkUsername, create, edit } from "@/services/studentService";
import passwordGenerator from "generate-password";
import _ from "lodash";
import moment from "moment";
import { mapActions, mapGetters } from "vuex";

export default {
  metaInfo: {
    title: "Student",
  },

  props: {
    isCreate: {
      type: Boolean,
      default: false,
    },
  },

  data() {
    return {
      id: _.get(this.$route, "params.id"),
      selectedCustomerName: "",
      gettingCustomers: false,
      showPassword: false,
      displayDate: null,
      loading: false,
      usernameError: [],
      usernameLoading: false,
      date: null,
      dialog: false,
      counter: 0,
      studentCustomers: [],
      student: {
        customer_id: null,
        first_name: null,
        last_name: null,
        name: null,
        email: null,
        username: null,
        password: null,
        function: null,
        company_name: null,
        number: null,
        birthdate: null,
        disabled: 0,
      },
      customerMeta: {
        page: 1,
        perPage: this.isCreate ? 10 : 0,
        search: null,
      },
      requiredRule: (v) => !!v || "This is required.",
      emailRule: (v) =>
        !!v ||
        /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/.test(v) ||
        "E-mail must be valid",
    };
  },

  async mounted() {
    if (!this.isCreate) {
      this.customerMeta.search = "";

      await this.setStudent(this.id);

      this.student = this.getStudent;
      this.student.company_name = _.get(
        this.student,
        "sign_up_student.company_name"
      );
      this.student.function = _.get(this.student, "sign_up_student.function");
      this.student.number = _.get(this.student, "sign_up_student.number");
      this.student.birthdate = _.get(this.student, "sign_up_student.birthdate");
      this.student.customer_name = _.get(this.student, "customer.description");

      this.onBirthdateChange();
    }

    await this.paginateCustomer();
  },

  watch: {
    "student.username": _.debounce(function () {
      this.validateUsername();
    }, 500),

    immediate: true,
    deep: true,
  },

  methods: {
    ...mapActions([
      "setStudents",
      "showSnackbar",
      "setStudentCustomers",
      "getCustomerById",
      "setStudent",
    ]),

    async paginateCustomer() {
      this.gettingCustomers = true;

      await this.setStudentCustomers({
        perPage: this.customerMeta.perPage,
        page: this.customerMeta.page,
      });

      this.studentCustomers = this.getStudentCustomers;
      this.gettingCustomers = false;
    },

    async onIntersect() {
      if (_.size(this.studentCustomers) >= this.getStudentCustomersTotal) {
        return;
      }

      this.gettingCustomers = true;

      await this.setStudentCustomers({
        perPage: this.customerMeta.perPage,
        page: (this.customerMeta.page += 1),
      });

      this.studentCustomers = [
        ...this.studentCustomers,
        ...this.getStudentCustomers,
      ];
      this.gettingCustomers = false;
    },

    async save() {
      this.loading = true;

      if (!this.$refs.form.validate() || !_.isEmpty(this.usernameError)) {
        this.$store.dispatch("showSnackbar", "One or more fields are invalid.");
        this.loading = false;

        return;
      }

      await this.showSnackbar(
        this.isCreate ? "Creating student..." : "Editing student..."
      );

      try {
        this.isCreate ? await create(this.student) : await edit(this.student);
        await this.showSnackbar(
          `Student ${this.isCreate ? "created" : "updated"}`
        );
        this.$router.push("/app/student");
      } catch (error) {
        await this.showSnackbar("Something went wrong");
      }

      this.loading = false;
    },

    async validateUsername() {
      this.usernameLoading = true;

      const { data } = await checkUsername({
        id: this.id,
        username: _.get(this.student, "username"),
      });

      this.usernameError = data ? ["Username already exists."] : [];
      this.usernameLoading = false;
    },

    async generateUserName(exists = false) {
      let username = "";

      if (_.get(this.student, "last_name")) {
        username = this.student.last_name
          .replace("/[^A-Za-z]/", "")
          .replace(/ /g, "");
      }

      if (_.get(this.student, "name")) {
        username = this.student.name
          .replace("/[^A-Za-z]/", "")
          .replace(/ /g, "");
      }

      if (username) {
        if (exists === true) {
          this.counter++;

          username += this.counter;
        }

        const { data } = await checkUsername({
          id: this.id,
          username,
        });

        if (data) {
          this.generateUserName(true);

          return;
        }

        this.counter = 0;
        this.student.username = username;
      }
    },

    generatePassword() {
      this.student.password = passwordGenerator.generate({
        length: 10,
        numbers: true,
        lowercase: true,
        uppercase: true,
        excludeSimilarCharacters: true,
      });
    },

    onBirthdateChange() {
      this.displayDate = moment(this.student.birthdate).format("DD/MM/YYYY");
    },
  },
  computed: {
    ...mapGetters([
      "getStudents",
      "getStudentCustomers",
      "getStudentCustomersTotal",
      "getStudent",
    ]),

    isStudentValid() {
      const conditions =
        !!_.get(this.student, "email") &&
        !!_.get(this.student, "customer_id") &&
        !!_.get(this.student, "username");

      const additionalConditions = this.isCreate
        ? !!_.get(this.student, "first_name") &&
          !!_.get(this.student, "last_name") &&
          !!_.get(this.student, "password")
        : !!_.get(this.student, "name");

      return conditions && additionalConditions;
    },
  },
};
</script>
<style lang="scss" scoped></style>
